import React, { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const usePageTracking = () => {
//   const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, [window.location]);
};

export default usePageTracking;
