import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import './Menu.css'
import logo from '../src/images/logo.png';

// import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Close } from '@material-ui/icons';
import { List, ListItem } from '@mui/material';
// import { ExpandLess, ExpandMore } from '@mui/icons-material';



const Menu = () => {

  // const [isTop, setIsTop] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  // const navigate = useNavigate();
  const [open, setOpen] = useState(false);


  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 800); // Adjust the breakpoint as needed
    };
    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);


  const handleToggle = () => {
    setOpen(!open);
  };
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {

  //   document.addEventListener('scroll', () => {
  //     const scrolled = window.scrollY;
  //     if (scrolled === 0) {
  //       setIsTop(true);

  //     } else {
  //       setIsTop(false);
  //     }
  //   });
  // }, []);


  return (
    <nav className="top-nav-bar">
      <div className="row mob-row d-flex flex-col justify-evenly">
        <div className=" logo d-flex justify-content-start" style={{ width: '250px' }}>
          <img src={logo} alt="Logo" />

        </div>
        <div className="d-flex justify-content-end align-items-center " style={{ inlineSize: 'fit-content' }}>
          {!isMobile &&
            <ul style={{ height: '50px' }}>
              <li className='nav-menu-item hover:text-glow'><a href='#home' className='nav-menu-item' to="/">Home</a></li>
              <li className='nav-menu-item hover:text-glow'><a href="#products" className='nav-menu-item'>Products</a>


              </li>
              <li className='nav-menu-item hover:text-glow'><a href='#pricing' className='nav-menu-item' to="/">Pricing</a></li>
              <li className='nav-menu-item hover:text-glow'><a href="#guides" className='nav-menu-item' to="/products/trends">Video Guides</a></li>
              {/* <li className='nav-menu-item '><Link className='nav-menu-item' to="">PAGES</Link>
                <ul className='nav-dropdown'>
                  <li className='dropdown-items'><Link to="/login">Login</Link></li>
                  <li className='dropdown-items'><Link to="">Tracking</Link></li>
                </ul>
              </li> */}
              <li className='nav-menu-item  hover:text-glow'><a href="#contact" className='nav-menu-item' to="">Contact</a></li>

            </ul>}



          {/* <div className='nav-menu-item m-2' onClick={onCheckout}>
            <StyledBadge badgeContent={TotalCartItems} color="secondary"> <ShoppingCartIcon>
            </ShoppingCartIcon></StyledBadge>
            </div> */}

          {/* <div className='nav-menu-item m-2'><SearchIcon></SearchIcon></div> */}
          {isMobile && <div className='nav-menu-item m-2 '><MenuIcon style={{ fontSize:'35px' }} onClick={toggleSidebar}></MenuIcon></div>}

          <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <Close style={{ position: 'absolute', fontSize:'30px', right: '20px', top: '20px', zIndex: '99' }} onClick={toggleSidebar}></Close>
            <nav style={{ paddingTop: '50px' }}>
              <List>
                <ListItem button >
                  <a className='nav-menu-item text-xl hover:text-glow no-underline' href='#home' onClick={() => { toggleSidebar(); }} >Home</a>
                </ListItem>
              </List>



              <List>
                <ListItem button onClick={handleToggle}>
                  {/* <ListItemIcon>
                    < />
                  </ListItemIcon> */}
                  <a className='nav-menu-item text-xl  hover:text-glow no-underline' href='#products' onClick={() => { toggleSidebar(); }} >Products</a>
                  {/* <ListItemText primary="Products" /> */}

                </ListItem>
                {/* <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button>
                      <ListItemText onClick={() => { navigate("/products/smart-watches"); toggleSidebar(); }} primary="Smart Watches" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText onClick={() => { navigate("/products/earbuds"); toggleSidebar(); }} primary="Earbuds" />
                    </ListItem>
                    <ListItem button>
                      <ListItemText onClick={() => { navigate("/products/power-banks"); toggleSidebar(); }} primary="Power Banks" />
                    </ListItem>
                  </List>
                </Collapse> */}
              </List>
              <List>
                <ListItem button >
                  <a className='nav-menu-item text-xl  hover:text-glow no-underline' href='#pricing' onClick={() => { toggleSidebar(); }} >Pricing</a>
                </ListItem>
              </List>
              <List>
                <ListItem button >
                  <a className='nav-menu-item  text-xl hover:text-glow no-underline' href='#guides' onClick={() => { toggleSidebar(); }} >Video Guides</a>
                </ListItem>
              </List>

              <List>
                <ListItem button >

                  <a className='nav-menu-item text-xl  hover:text-glow no-underline' href='#contact' onClick={() => { toggleSidebar(); }} >Contact</a>
                  {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItem>
                {/* <Collapse in={false} timeout="auto" unmountOnExit>

                </Collapse> */}
              </List>
            </nav>
          </div>

          {/* <button className='btn btn-primary' type="submit">Login</button>
          <button className='btn btn-primary' type="submit">Register</button> */}
        </div>
      </div>

    </nav>
  );
};

export default Menu;
