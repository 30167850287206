import React from 'react';

import './PricingTable.css';

const PricingTable = () => {
    const packages = [
        {
            name: 'Basic',
            price: '$9',
            features: [true, false, false, false],
        },
        {
            name: 'Standard',
            price: '$49',
            features: [true, true, false, false],
        },
        // {
        //     name: 'Premium',
        //     price: '$49',
        //     features: [true, true, true, false],
        // },
        // {
        //     name: 'Ultimate',
        //     price: '$150',
        //     features: [true, true, true, true],
        // },
    ];

    

    return (
        <div className="pricing-table-container">
            <table className="bg-gray-900 pricing-table">
                <thead>
                    <tr>
                        <th>Package</th>
                        {packages.map((pkg, index) => (
                            <th className='text-[#ff914d] text-xl' key={index}>{pkg.name}</th>
                        ))}
                    </tr>
                    <tr>
                        <td>Expiry</td>
                        <td>1 Month</td>
                        <td>6 Months</td>
                        {/* <td>1 Month</td>
                        <td className=''>1 Year</td> */}
                    </tr>
                    <tr>
                        <th>1 Account</th>
                        <td>$9</td>
                        <td>$29</td>
                        
                    </tr>
                    <tr>
                        <th>Upto 3 Accounts</th>
                        <td>$6 each</td>
                        <td>$19 each</td>
                        
                    </tr>
                    <tr>
                        <th>Upto 6 Accounts</th>
                        <td>$5 each</td>
                        <td>$15 each</td>
                        
                    </tr>
                </thead>
                <tbody>
                    
                    
                    {/* {featureList.map((feature, featureIndex) => (

                        <tr key={featureIndex}>
                            <td >{feature}</td>
                            {packages.map((pkg, pkgIndex) => (
                                <td key={pkgIndex}>
                                    {pkg.features[featureIndex] ? (
                                        <DoneIcon  style={{color:'#ff914d', marginRight:'5px'}} />
                                    ) : (
                                        <CloseIcon style={{color:'red', marginRight:'5px'}} />
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))} */}
                     <tr>
                        <th>VPS Hosting</th>
                        <td>$3 per month<br />per account</td>
                        <td>$3 per month<br />per account</td>
                        {/* <td>$2 per month <br />per account</td>
                        <td>$2 per month <br />per account</td> */}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PricingTable;
