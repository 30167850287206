// src/components/SlideInSection.js
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const SlideInSection = ({ children, direction }) => {
  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setIsVisible(entry.isIntersecting));
    });
    const currentRef = domRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => observer.unobserve(currentRef);
  }, []);

  let initialTransform;
  switch (direction) {
    case 'left':
      initialTransform = '-translate-x-full';
      break;
    case 'right':
      initialTransform = 'translate-x-full';
      break;
    case 'up':
      initialTransform = '-translate-y-full';
      break;
    case 'down':
      initialTransform = 'translate-y-full';
      break;
    default:
      initialTransform = '-translate-x-full';
  }

  return (
    <div
      className={`transform transition-transform duration-1000 ${
        isVisible ? 'translate-x-0 translate-y-0 opacity-100' : `${initialTransform} opacity-0`
      }`}
      ref={domRef}
    >
      {children}
    </div>
  );
};

SlideInSection.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
};

SlideInSection.defaultProps = {
  direction: 'left',
};

export default SlideInSection;
