
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';

import usePageTracking from './usePageTracking';

function App() {
  usePageTracking();
  return (
    <div className="App">
       <BrowserRouter>
          <div className="pages">
            <Routes>
              
              <Route path="/" element={<Home />} />
              

            </Routes>
          </div>
        </BrowserRouter>
      
    </div>
  );
}

export default App;
