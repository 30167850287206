// PricingCards.js file
import React from "react";
import DoneIcon from '@mui/icons-material/Done';

const PricingCards = () => {
  const cardData = [
    {
      image: "https://i.imgur.com/Ql4jRdB.png",
      title: "Tradelocker",
      title1: "Metatrader 5",
      price: "$9 to $49",
      price1: "$10 per account monthly",
      price2: "$49 unlimted accounts monthly",
      price3: "$49 per account yearly",
      price4: "$149 unlimited accounts yearly",
      features: [ "Copy positions and orders", "Modify SL and TP", "Suffix Symbols", "Risk Manager"]
    },
    
    {
      image: "https://i.imgur.com/Hg0sUJP.png",
      title: "Metatrader 5",
      title1: "Tradelocker",
      price: "$9 to $49",
      price1: "$10 per account monthly",
      price2: "$49 unlimted accounts monthly",
      price3: "$49 per account yearly",
      price4: "$149 unlimited accounts yearly",
      features: ["Copy positions and orders", "Modify SL and TP", "Suffix Symbols","Risk Manager"]
    },
    {
      image: "https://i.imgur.com/Hg0sUJP.png",
      title: "Tradelocker",
      title1: "Tradelocker",
      price: "$9 to $49",
      price1: "$10 per account monthly",
      price2: "$49 unlimted accounts monthly",
      price3: "$49 per account yearly",
      price4: "$149 unlimited accounts yearly",
      features: [ "Copy positions and orders", "Modify SL and TP", "Suffix Symbols","Risk Manager"]
    },
    {
      image: "https://i.imgur.com/Hg0sUJP.png",
      title: "Telegram",
      title1: "MT4/MT5",
      price: "$9 to $49",
      price1: "$10 per account monthly",
      price2: "$49 unlimted accounts monthly",
      price3: "$49 per account yearly",
      price4: "$149 unlimited accounts yearly",
      features: ["Copy positions & orders", "Modify SL & TP","Breakeven & close partial","Suffix Symbols","Risk Manager"]
    }
    
  ];
  return (
    <div className="w-full  bg-black">
      <div className="max-w-[1080px] px-4 mx-auto grid md:grid-cols-3 gap-8">
        {cardData.map((card, index) => (
          <div
            key={index}
            className={`w-full hover:box-glow  shadow-xl flex flex-col p-3 my-4 bg-gray-900 rounded-lg hover:scale-105 duration-300`}
          >
            {/* <img
              className="w-20 mx-auto mt-[-3rem] bg-white"
              src={card.image}
              alt="/"
            /> */}
            <h3 className="text-2xl font-bold text-center py-2">
              {card.title}<br />
              to<br />
              {card.title1}
            </h3>
            <span className="text-center font-bold text-[#ff914d]"><h4>{card.price}</h4>Monthly / Yearly</span>
            {/* <span className="text-center font-bold text-[#ff914d]"><h5>{card.price2}</h5></span>
            <span className="text-center font-bold text-[#ff914d]"><h5>{card.price3}</h5> </span>
            <span className="text-center font-bold text-[#ff914d]"><h5>{card.price4}</h5></span> */}

            <div className="text-left font-small">
              {card.features.map((feature, index) => (
                <p
                  key={index}
                  className={` mx-4 text-gray-400 ${index === 0 ? "mt-4" : ""}`}
                >
                  <DoneIcon style={{color:'#ff914d', marginRight:'5px'}} />
                  {feature}
                </p>
              ))}
            </div>
            <a href="#contact"
              className={` no-underline mb-1 relative bg-[#ff914d] text-white hover:text-[#f7a471] hover:bg-[#f7a471] duration-150 w-[150px] rounded-md font-medium my-6 mx-auto px-6 py-3`}
            >
              Contact Us
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingCards;